

import http from "@/utils/request";

// 专卖店绑定押金支付凭证及首批款附件
export function saveSpecialShopFiles(data) {
    return http({
        method: "post",
        url: "/custShop/saveSpecialShopFiles.nd",
        data
    });
}


// 专卖店绑定押金支付凭证及首批款附件
export function saveSpecialShopFiles2(data) {
    return http({
        method: "post",
        url: "/custShop/saveSpecialShopFiles.nd",
        data,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    });
}

// 线上支付跳电子收银台
export function payInfo(data) {
    return http({
        method: "post",
        url: "/custShop/payInfo.nd",
        data,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    });
}

// 专卖店押金流水信息
export function specialShopTransactions(data) {
    return http({
        method: "post",
        url: "/custShop/specialShopTransactions.nd",
        data,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    });
}

