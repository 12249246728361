
import { saveSpecialShopFiles, saveSpecialShopFiles2, payInfo, specialShopTransactions} from './api'
import axios from "axios";
import { publicPath, cisPublicPath2 } from "@/common/constant.js";
import moment from 'moment';
import { mapState } from "vuex";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  // components: {
  //   VNodes: {
  //     functional: true,
  //     render: (h, ctx) => ctx.props.vnodes,
  //   },
  // },
  name: "",
  data() {
    return {
      // 面包屑
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/center/todoList",
          name: "",
          title: "待办"
        },
        {
          path: "/confirm/channelInput",
          name: "",
          title: "专卖店待办"
        }
      ],
      isExist: true,  //是否存在押金交易流水，true存在，false不存在
      dbId:'', // 待办id
      specialShopCheckId:'', // 专卖店审批id
      custFullName:'', // 商户名
      downPayment:'', // 装修押金
      specialShopName:'',//专卖店门店名称
      specialShopCisCode:'',//专卖店门店cis编码
      isShowDeposit:false,//专卖店是否做展台
      isNewCreate:false,//专卖店是否新建
      isExistsFlag: false,// 是否交押金（true，false）
      DepositMoney:'', // 押金具体金额（元）
      transactionsId:'', // 交易流水id

      fileList: [],
      fileIds:[],// 附件id

      fileList2: [],
      fileIds2:[],// 附件id

      previewImage: '',
      previewVisible: false,

      pageLoading:false,
      payMethod: 2, // 支付方式
      
    };
  },
  
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
        ? this.$store.state.user.userInfo
        : localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo"))
          : "";
    },
    ...mapState({
      //处理后的筛选数据list
      isZMIsPay: state => state.user.isZMIsPay,
    })
  },
  watch: {
  },

  mounted() {
    const this_ = this;
    window.addEventListener('storage', (e) => {
      if(e.key == "isZMIsPay" && e.newValue == '1'){
       
        const timer = setInterval(() => {
          // 轮训判断是否支付成功 成功-> 关闭loading
          payInfo(params).then(res =>{
            if(res.data && res.data.data && res.data.data.errCode != "TARGET_HAS_PAID" && res.data.data.payType == 'unionPay') {
              this.pageLoading = false;
              clearInterval(timer);
            }
          })
          // specialShopTransactions({
          //   specialShopCode: this.specialShopCisCode
          // }).then(res=>{
          //   if(res.data && res.data.message == '查询成功'){
          //     this.isExist = res.data.data.isExist;  //是否存在押金交易流水，true存在，false不存在
          //     if(!res.data.data.isExist){
          //       clearInterval(timer)
          //       this_.pageLoading = false;
          //     }
          //   }
          // })
        }, 2000);
        
        setTimeout(() => {
          // 三分钟后
          if(this.pageLoading){
            this.pageLoading = false;
            clearInterval(timer);
          }
        }, 180000);
      }
    })
    if (this.$route.query.id && this.$route.query.sourceUrl) {
      console.log(54564,JSON.parse(this.$route.query.sourceUrl))
      this.dbId = this.$route.query.id; // 待办id
      // specialShopName：专卖店门店名称
      // specialShopCisCode：专卖店门店cis编码
      // isShowDeposit：专卖店是否做展台
      // isNewCreate：专卖店是否新建
      // isExistsFlag：是否交押金（true(无需交押金)，false）
      // DepositMoney：押金具体金额（元）
      // transactionsId：交易流水id
      const {checkId, custFullName, downPayment, specialShopName, specialShopCisCode, isShowDeposit, isNewCreate, isExistsFlag, DepositMoney, transactionsId} = JSON.parse(this.$route.query.sourceUrl)
      this.specialShopCheckId = checkId;
      this.custFullName = custFullName;
      this.downPayment = downPayment;
      this.specialShopName = specialShopName;
      this.specialShopCisCode = specialShopCisCode;
      this.isShowDeposit = isShowDeposit;
      this.isNewCreate = isNewCreate;
      this.isExistsFlag = isExistsFlag;
      this.DepositMoney = DepositMoney;
      this.transactionsId = transactionsId;

      specialShopTransactions({
        specialShopCode: this.specialShopCisCode
      }).then(res=>{
        if(res.data && res.data.message == '查询成功'){
          this.isExist = res.data.data.isExist;  //是否存在押金交易流水，true存在，false不存在
          if(this.isExist){
            this.payMethod = 1
          }
        }
      })
    }

   
  },   

  methods: {
    onPayChange(type){
      if(type == 1){
        // if(this.transactionsId && this.DepositMoney && this.checkId){
        const params = {
          transactionId:this.transactionsId,
          downPayment:this.DepositMoney,
          checkId:this.specialShopCheckId
        }
        
          this.pageLoading = true
          payInfo(params).then(res =>{
            if(res.data && res.data.data && res.data.data.errCode == "SUCCESS" && res.data.data.pcOrderPagetUrl){
              window.open(res.data.data.pcOrderPagetUrl)
            }else if(res.data && res.data.data && res.data.data.errCode != "SUCCESS") {
              this.pageLoading = false
              this.$message.warning(res.data.data.errMsg)
            }else{
              this.pageLoading = false
              this.$message.warning('电子收银台拉取失败')
            }
            // this.pageLoading = false
          }).catch(error =>{
            this.pageLoading = false
            this.$message.warning('电子收银台拉取失败')
          })
        // }
      }
      
      console.log(type)
      // alert(type)
    },

    // 装修押金打款凭证上传
    beforeUpload1(file,type) {
      if ((file.size) / 1024 > 30720) {
        this.$message.warning("附件大小不能大于30M");
        let timer = setTimeout(()=>{
          this.fileList = this.fileList.slice(0,-1)
          clearTimeout(timer)
        },1)
        return false;
      }
      // this.fileList = [...this.fileList, file];
      // 获取文件base64
      getBase64(file).then(res=>{
        const fileBase64 = res;
        // const formData = new FormData();
        // formData.append("fileName", file.name);
        // formData.append("file", fileBase64);
        // formData.append("fileType", "deposit");

        // let config = {
        //   headers: { 'content-type': 'multipart/form-data' }
        // }

        const params = {
          file: fileBase64,
          fileName: file.name,
          fileType: 'deposit',
          serviceCode: 'specialUploadFile'
        }
        this.pageLoading = true;
        // 发送请求
        const BASE_API = publicPath;
        axios.post(BASE_API + '/cis/basis-rest/specialUploadFile.nd', params).then(res => {
          if (res.data.flag) {
            this.fileIds.push(res.data.id)
          
          } else {
            this.$message.warning('上传失败')
            this.fileList = this.fileList.slice(0,-1)
          }
          this.pageLoading = false;
        }).catch(err=>{
          this.pageLoading = false;
          this.$message.warning('上传失败')
          this.fileList = this.fileList.slice(0,-1)
        })
      }).catch(err=>{
        this.$message.warning('图片转码失败')
      })
      
      return false;
    },


    // 首批款打款凭证上传
    beforeUpload2(file,type) {
      if ((file.size) / 1024 > 30720) {
        this.$message.warning("附件大小不能大于30M");
        let timer = setTimeout(()=>{
          this.fileList2 = this.fileList2.slice(0,-1)
          clearTimeout(timer)
        },1)
        return false;
      }

      // 获取文件base64
      getBase64(file).then(res=>{
        const fileBase64 = res;
        // const formData = new FormData();
        // formData.append("fileName", file.name);
        // formData.append("file", fileBase64);
        // formData.append("fileType", "first");

        // let config = {
        //   headers: { 'content-type': 'multipart/form-data' }
        // }

        const params = {
          file: fileBase64,
          fileName: file.name,
          fileType: 'first',
          serviceCode: 'specialUploadFile'
        }
        this.pageLoading = true;
        // 发送请求
        const BASE_API = publicPath;
        axios.post(BASE_API + '/cis/basis-rest/specialUploadFile.nd', params).then(res => {
          if (res.data.flag) {
            this.fileIds2.push(res.data.id)
          
        } else {
            this.$message.warning('上传失败')
            this.fileList2 = this.fileList2.slice(0,-1)
          }
          this.pageLoading = false;
        }).catch(err=>{
          this.pageLoading = false;
          this.$message.warning('上传失败')
          this.fileList2 = this.fileList2.slice(0,-1)
        })
      }).catch(err=>{
        this.$message.warning('图片转码失败')
      })
      return false;
    },

    handleChange(fileObj,type) {
      if(type == 1){
        this.fileList = fileObj.fileList;
      }else{
        this.fileList2 = fileObj.fileList;
      }
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      this.fileIds.splice(index, 1)
    },
    handleRemove2(file) {
      const index = this.fileList2.indexOf(file);
      const newFileList = this.fileList2.slice();
      newFileList.splice(index, 1);
      this.fileList2 = newFileList;
      this.fileIds2.splice(index, 1)
    },

    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleCancel() {
      this.previewVisible = false;
    },



    goback(){
      
      this.$router.push({
        path: "/center/todoList"
      });
    },
    sumbit(){
      if(!this.specialShopCheckId){
        this.$message.warning('未获取到专卖店审批id')
        return
      }
      // 判断是否已交押金(已交押金则展示线上已付款样式) -> (否:未交押金)判断是否线上支付
      if(!this.isExistsFlag && this.isShowDeposit && !this.isExist){
        if(this.payMethod == 2 && this.fileIds.length == 0){
          // 线下支付且上传附件为空
          this.$message.warning('尚未上传装修押金打款凭证，请上传装修押金打款凭证')
          return
        }
        // 非必填 可不判断已选
        // else if(this.payMethod == ''){
        //   this.$message.warning('请上传装修押金打款凭证支付方式')
        //   return
        // }
      }
      
      if(this.isNewCreate && this.fileIds2.length == 0) {
        this.$message.warning('尚未上传商家首批款凭证，请先行上传首批款凭证')
        return
      }
      let data  = {
        "id": this.dbId,// 待办id
        "specialShopCheckId": this.specialShopCheckId,//绑定专卖店审批id
        "specialDepositAttachs": this.fileIds,
        "specialFirstAttachs": this.fileIds2,
        isNewCreate: this.isNewCreate,
        isShowDeposit: this.isShowDeposit,
        payMethod:!this.isExistsFlag && this.isShowDeposit ? this.payMethod : ''
      }
      this.pageLoading = true
        saveSpecialShopFiles2(data).then (res =>{
          if(res.data.code == 1){
            this.pageLoading = false
            this.$message.success(res.data.message)
            this.timer = setTimeout(() => {  //设置延迟执行
              this.$router.push({
                path: "/center/todoList"
              });
            }, 2000);
          } else {
            this.pageLoading = false
            this.$message.warning(res.data.message)
          }
        }).catch(error =>{
          this.pageLoading = false
          console.log(error);
        })
      

    },
  }

};